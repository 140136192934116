.horizontal-menu {
  padding-left: 10px;
}
.menu-item {
  padding: 0;
  margin: 0;
  user-select: none;
  cursor: pointer;
  border: 0px transparent solid;
}
.menu-item-wrapper {
  margin: 0;
  padding: 0;
}
.menu-item-wrapper.active {
  margin: 0;
  padding: 0;
}
.menu-item.active {
  margin: 0;
  padding: 0;
}

.scroll-menu-arrow {
  margin: 0;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;

  /* display: none; */
}
